import React from "react";

const IntroBlock = ({ title, subtitle }) => {
  return (
    <div className="bg-light-yellow px-gutter pt-[150px] sm:pt-[200px] lg:pt-[341px] ">
      <div className="max-w-[831px]">
        <h1 className="richH1 mb-[30px]">{title}</h1>
        <h2 className="richH2 text-grey">{subtitle}</h2>
      </div>
    </div>
  );
};

export default IntroBlock;
