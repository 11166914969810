import React from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

const ImageText = loadable(() => import('./modules/imageText'));
const TextBlock = loadable(() => import('./modules/textBlock'));
const ImageCarousel = loadable(() => import('./modules/imageCarousel'));
const AnimatedStats = loadable(() => import('./modules/animatedStats'));
const LargeCard = loadable(() => import('./modules/largeCard'));
const LogoCarousel = loadable(() => import('./modules/logoCarousel'));
const SingleStatBlock = loadable(() => import('./modules/singleStatBlock'));
const ClientReviews = loadable(() => import('./modules/clientReviews'));
const PageBanner = loadable(() => import('./modules/pageBanner'));
const TwoColumnText = loadable(() => import('./modules/twoColumnText'));
const CardAccordion = loadable(() => import('./modules/cardAccordion'));
const CtaBlock = loadable(() => import('./modules/ctaBlock'));
const CardsBlock = loadable(() => import('./modules/cardsBlock'));
const ApplicationBlock = loadable(() => import('./modules/applicationBlock'));
const FastApprovalBlock = loadable(() => import('./modules/fastApprovalBlock'));
const BusinessPropertyBLock = loadable(() =>
  import('./modules/businessPropertyBLock')
);
const AccordionSet = loadable(() => import('./modules/accordionSet'));
const TeamGrid = loadable(() => import('./modules/teamGrid'));
const FilteredTeamGrid = loadable(() => import('./modules/filteredTeamGrid'));
const SolutionsCarousel = loadable(() => import('./modules/solutionsCarousel'));
const FilteredCardsBlock = loadable(() =>
  import('./modules/filteredCardsBlock')
);

const ModuleZone = ({ modules, solutionType }) => {
  const component = modules.map((item, index) => {
    switch (item._type) {
      case 'imageText':
        return <ImageText {...item} key={`image-text-${index}`} />;
      case 'textBlock':
        return <TextBlock {...item} key={`text-block-${index}`} />;
      case 'imageCarousel':
        return <ImageCarousel {...item} key={`image-carousel-${index}`} />;
      case 'animatedStats':
        return <AnimatedStats {...item} key={`animated-stats-${index}`} />;
      case 'largeCard':
        return <LargeCard {...item} key={`large-card-${index}`} />;
      case 'logoCarousel':
        return <LogoCarousel {...item} key={`logo-carousel-${index}`} />;
      case 'singleStatBlock':
        return <SingleStatBlock {...item} key={`single-stat-${index}`} />;
      case 'clientReviews':
        return <ClientReviews {...item} key={`client-reviews-${index}`} />;
      case 'pageBanner':
        return <PageBanner {...item} key={`page-banner-${index}`} />;
      case 'twoColumnText':
        return <TwoColumnText {...item} key={`two-column-${index}`} />;
      case 'cardAccordion':
        return <CardAccordion {...item} key={`card-accordion-${index}`} />;
      case 'ctaBlock':
        return <CtaBlock {...item} key={`cta-${index}`} />;
      case 'cardsBlock':
        return <CardsBlock {...item} key={`cards-${index}`} />;
      case 'accordionSet':
        return <AccordionSet {...item} key={`accordion-set-${index}`} />;
      case 'filteredCardsBlock':
        return (
          <FilteredCardsBlock
            {...item}
            solutionType={solutionType}
            key={`filtered-cards-${index}`}
          />
        );
      case 'teamGrid':
        return <TeamGrid {...item} key={`team-grid-${index}`} />;
      case 'filteredTeamGrid':
        return <FilteredTeamGrid {...item} key={`filtered-team-${index}`} />;
      case 'solutionsCarousel':
        return (
          <SolutionsCarousel {...item} key={`solution-carousel-${index}`} />
        );
      case 'applicationBlock':
        return (
          <ApplicationBlock {...item} key={`application-block-${index}`} />
        );
      case 'fastApprovalBlock':
        return <FastApprovalBlock {...item} key={`fast-approval-${index}`} />;
      case 'businessPropertyBLock':
        return (
          <BusinessPropertyBLock {...item} key={`business-property-${index}`} />
        );
      default:
        return null;
    }
  });
  return <>{component}</>;
};

ModuleZone.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default ModuleZone;
