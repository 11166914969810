import React from "react";
import { graphql } from "gatsby";
import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import IntroBlock from "@components/other/introBlock";

const Faqs = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data?.page;
  const { modules, seo, title, subtitle } = page || {};
  return (
    <Layout reversedHeader={true}>
      <PageMeta {...seo} />
      <IntroBlock title={title} subtitle={subtitle} />
      {modules && <ModuleZone {...modules} />}
    </Layout>
  );
};

export default Faqs;

export const pageQuery = graphql`
  query sanityFaqPageQuery {
    page: sanityFaqPage {
      title
      subtitle
      seo {
        ...SEO
      }
      modules {
        ...FaqModules
      }
    }
  }
`;
